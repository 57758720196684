export default {
  app_ame: "AirFormTable",
  locale: "en",
  locales: { en: "EN" },
  githubAuth: null,
  airtable: {
    worker: "https://notion-forms-worker.notionforms.workers.dev/v1",
    unsupported_form_types: [
      'formula',
      'rollup',
      'lookup',
      'multipleLookupValues',
      'singleCollaborator',
      'multipleCollaborators',
      'autoNumber',
      'createdTime',
      'createdBy',
      'lastModifiedTime',
      'lastModifiedBy',
      'count',
      'barcode',
      'button',
      'externalSyncSource',
    ],
    unsupported_view_types: [
      "created_time",
      "created_by",
      "last_edited_time",
      "last_edited_by",
      "unique_id",
    ],
    database_filterable_types: [
      "title",
      "rich_text",
      "url",
      "email",
      "phone_number",
      "number",
      "checkbox",
      "select",
      "multi_select",
      "status",
      "date",
      "people",
      "relation",
    ],
  },
  links: {
    help_url: "https://help.airformtable.com/",
    changelog_url: "https://noteforms.canny.io/changelog",
    facebook_group: "https://www.facebook.com/groups/notionforms/",
    twitter: "https://twitter.com/NotionForms",
    status: "https://status.notionforms.io/",
    zapier_integration:
      "https://zapier.com/developer/public-invite/146950/58db583730cc46b821614468d94c35de/",
    book_onboarding: "https://zcal.co/i/YQVGEULQ",
    feature_requests: "https://noteforms.canny.io/feature-requests",
    testimonial: "https://testimonial.to/noteforms/all",
    helpdesk_sitemap_url: "https://notionforms.crisp.help/sitemap.xml",
  },
  production: false,
}
